import React from "react";
import {AiOutlineMail, AiOutlineWhatsApp, AiFillLinkedin, AiOutlineGithub} from "react-icons/ai";

const Footer = () => {
	return (
		<div id="Contact" className="w-full bg-gray-100 py-16 ">
			<div className="sm:max-w-[640px] lg:max-w-[1024px] mx-auto flex flex-col px-4">
				<div className="sm:flex text-center justify-between items-center">
					<a href="/#">
						<h1>BRASIL</h1>
					</a>
					<br></br>
					<div className=" flex justify-center w-full sm:max-w-[380px] my-4 ">
						<a
							href="mailto:gimernahuel.work@gmail.com"
							className="flex flex-col items-center ml-10 mr-10"
						>
							<AiOutlineMail color="#000" className="icon justify-center" />
							<span>Email</span>
						</a>
						<a href="https://wa.me/5491126101998" className="flex flex-col items-center mr-10">
							<AiOutlineWhatsApp color="#000" className="icon justify-center" />
							<span>WhatsApp</span>
						</a>
						<a
							href="https://www.linkedin.com/in/nahuelgimer/"
							className="flex flex-col items-center mr-10"
						>
							<AiFillLinkedin color="#000" className="icon justify-center" />
							<span>LinkedIn</span>
						</a>
						<a href="https://github.com/NahuelGimer" className="flex flex-col items-center mr-10">
							<AiOutlineGithub color="#000" className="icon justify-center" />
							<span>Github</span>
						</a>
					</div>
				</div>
				<span className="flex justify-center py-8">© 2023 Nahuel Gimer.</span>
			</div>
		</div>
	);
};

export default Footer;
