import React from "react";
/* import Carousel from "./components/Carousel"; */
/* import Destinations from "./components/Destinations"; */
import Viewer from "./components/Viewer";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Services from "./components/Services";
import Selects from "./components/Selects";
/* import Search from "./components/Search"; */
import Footer from "./components/Footer";

function App() {
	return (
		<div>
			<Navbar />
			<Hero />
			<Services />
			{/* <Search />*/}
			<Selects />
			<Viewer />
			<Footer />
		</div>
	);
}

export default App;
