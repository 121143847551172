import React from "react";
import servicesbg from "../assets/servicesbg.jpg";
import SelectsService from "./SelectsCard";
import {FaMapMarkedAlt} from "react-icons/fa";
import {FaPlaneDeparture} from "react-icons/fa";
import {FaHotel} from "react-icons/fa";

const Selects = () => {
	return (
		<div id="Travel" className="mt-28 w-full mx-auto ">
			<div id="Services" className="relative py-2 w-full">
				<div className="title-wrap relative bottom-24 flex">
					<h2 className="lg-title absolute z-10 inset-0 w-full flex justify-center ">
						Our services
					</h2>
				</div>
				<SelectsService
					className="service_image relative h-full w-full"
					bg={servicesbg}
				></SelectsService>
				<div className="absolute services-row lg-title inset-0 w-full flex justify-center">
					<a href="/#" className="services-item services z-10">
						<div className="services-icon">
							<FaPlaneDeparture className="services_icon"></FaPlaneDeparture>
						</div>
						<h3>Travel</h3>
						<div>
							<span>On time to </span>
							<span> new places.</span>
						</div>
					</a>
					<a href="/#" className="services-item services z-10 ">
						<div className="services-icon">
							<FaMapMarkedAlt className="services_icon"></FaMapMarkedAlt>
						</div>
						<h3>Adventure</h3>
						<div className="services-general">
							<span>Get to know</span>
							<span> Brasil.</span>
						</div>
					</a>

					<a href="/#" className="services-item services z-10 ">
						<div className="services-icon">
							<FaHotel className="services_icon"></FaHotel>
						</div>
						<h3>Hotel</h3>
						<div>
							<span>5 Star hotel</span> <span> booking.</span>
						</div>
					</a>
				</div>
			</div>
		</div>
	);
};

export default Selects;
