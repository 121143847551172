import React from "react";

import Buzios from "../assets/buzios.jpg";
import Copacabana from "../assets/copacabana.jpg";
import Pandeazucar from "../assets/pandeazucar.jpg";
import Riodejaneiro from "../assets/riodejaneiro.jpg";
import SelectsCard from "./SelectsCard";

const Selects = () => {
	return (
		<div id="Places" className="  mx-auto h-auto py-5 grid lg:grid-cols-2 gap-4">
			<a href="/#" className="selectCard ">
				<SelectsCard bg={Riodejaneiro} alt="Rio de Janeiro" text="Río de Janeiro" />
			</a>
			<a href="/#" className="selectCard ">
				<SelectsCard bg={Buzios} alt="Buzios" text="Búzios" />
			</a>
			<a href="/#" className="selectCard ">
				<SelectsCard bg={Copacabana} alt="Copacabana" text="Copacabana" />
			</a>

			<a href="/#" className="selectCard ">
				<SelectsCard bg={Pandeazucar} alt="Pan de Azucar" text="Pan de Azúcar" />
			</a>
		</div>
	);
};

export default Selects;
