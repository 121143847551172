import React from "react";
import {AiOutlineSearch} from "react-icons/ai";
import viewer from "../assets/viewer.mp4";

const Viewer = () => {
	return (
		<a href="/#" className=" overflow-hidden w-full h-full npm start relative">
			<video className="w-full object-cover" alt="Buzios" src={viewer} autoPlay loop muted />
		</a>
	);
};

export default Viewer;
